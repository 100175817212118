import React from "react"
import PageLayout from "../../components/PageLayout"

const ContactSuccess = () => (
  <PageLayout page="Contact Us | Success">
    <p className="mb-6">Thank you! Your message has been sent.</p>
  </PageLayout>
)

export default ContactSuccess
